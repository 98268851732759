<template>
  <div class="404">
    <h1>404</h1>
    <h1>No encontrado</h1>
  </div>
</template>

<script>

export default {
  name: "not-found",
  components: {
  
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>

</style>
